import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-left">
                <ul>
                    <li><Link to="/faq">Help and Support</Link></li>
                    <li><Link to="/terms">Terms of Service</Link></li>
                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                    <li><Link to="/contact">Contact Us</Link></li>
                </ul>
            </div>
            <div className="footer-right">
                <div className="footer-apps">
                    <a href={process.env.REACT_APP_APP_STORE_LINK} target="_blank" rel="noopener noreferrer">
                        <img src="app-store-badge.png" alt="App Store" />
                    </a>
                    <a href={process.env.REACT_APP_GOOGLE_PLAY_LINK} target="_blank" rel="noopener noreferrer">
                        <img src="google-play-badge.png" alt="Google Play" />
                    </a>
                </div>
                <div className="footer-social">
                    <a href={process.env.REACT_APP_INSTAGRAM_LINK} target="_blank" rel="noopener noreferrer">
                        <img src="instagram.png" alt="Instagram" />
                    </a>
                    <a href={process.env.REACT_APP_FACEBOOK_LINK} target="_blank" rel="noopener noreferrer">
                        <img src="facebook.png" alt="Facebook" />
                    </a>
                    <a href={process.env.REACT_APP_TIKTOK_LINK} target="_blank" rel="noopener noreferrer">
                        <img src="tiktok.png" alt="Tiktok" />
                    </a>
                </div>
            </div>
            <div className="footer-bottom">
            <p>&copy; 2024 by <a href={process.env.REACT_APP_BRANDING_ROUTES_LINK} target="_blank" rel="noopener noreferrer">Branding routes</a>. All Rights Reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
