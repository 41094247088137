import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import Modal from 'react-modal';
import './Screenshots.css';
import { FaSun, FaMoon } from 'react-icons/fa';

const lightModeScreenshots = [
  'light_screenshot5.jpg',
  'light_screenshot2.jpg',
  'light_screenshot12.jpg',
  'light_screenshot4.jpg',
  'light_screenshot9.jpg',
  'light_screenshot6.jpg',
  'light_screenshot7.jpg',
  'light_screenshot10.jpg'
];

const darkModeScreenshots = [
  'night_screenshot5.jpg',
  'night_screenshot2.jpg',
  'night_screenshot12.jpg',
  'night_screenshot4.jpg',
  'night_screenshot9.jpg',
  'night_screenshot6.jpg',
  'night_screenshot7.jpg',
  'night_screenshot10.jpg'
];

const screenshotDescriptions = [
  'Access extensive workout library.',
  'Quick workout planning.',
  'Track your progress with detailed analytics.',
  'Import and save workout logs.',
  'Choose from a variety of exercises targeting different muscle groups.',
  'Get animated demonstrations of exercises and required equipments.',
  'Customize your profile and toggle between dark and light modes.',
  'Adjust exercises, reps, and weights.'
];

const Screenshots = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [dragging, setDragging] = useState(false);
  const [isLightMode, setIsLightMode] = useState(true);
  const [tooltip, setTooltip] = useState({ visible: false, x: 0, y: 0, text: '' });

  const startPos = useRef({ x: 0, y: 0 })

  const openModal = (src) => {
    if (!dragging) {
      setSelectedImage(src);
      setModalIsOpen(true);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage('');
  };

  const handleMouseDown = (e) => {
    setDragging(false);
    startPos.current = { x: e.clientX, y: e.clientY }; // Record start position
  };

  const handleMouseUp = (e, src) => {
    const endPos = { x: e.clientX, y: e.clientY };
    const distance = Math.sqrt(
      Math.pow(endPos.x - startPos.current.x, 2) +
      Math.pow(endPos.y - startPos.current.y, 2)
    );
    if (distance < 5) { // Threshold for considering it a click
      openModal(src);
    } else {
      setDragging(true);
    }
  };

  const toggleMode = () => {
    setIsLightMode(!isLightMode);
  };

  const handleMouseEnter = (index, e) => {
    setTooltip({
      visible: true,
      x: e.clientX,
      y: e.clientY,
      text: screenshotDescriptions[index] || 'No description available',
    });
  };

  const handleMouseMove = (e) => {
    if (tooltip.visible) {
      setTooltip((prev) => ({
        ...prev,
        x: e.clientX,
        y: e.clientY,
      }));
    }
  };

  const handleMouseLeave = () => {
    setTooltip({ visible: false, x: 0, y: 0, text: '' });
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
    swipeToSlide: true,
    draggable: true,
  };

  const screenshots = isLightMode ? lightModeScreenshots : darkModeScreenshots;

  return (
    <section id="screenshots" className="screenshots-section">
      <div className="screenshots-container">
        <h2>App Screenshots</h2>
        <button className="mode-toggle" onClick={toggleMode}>
          {isLightMode ? <FaMoon /> : <FaSun />}
        </button>
        <Slider {...settings}>
          {screenshots.map((src, index) => (
            <div
              key={index}
              className="screenshot-container"
              onMouseDown={handleMouseDown}
              onMouseUp={(e) => handleMouseUp(e, src)}
            >
              <img
                src={src}
                alt={`Screenshot ${index + 1}`}
                onMouseEnter={(e) => handleMouseEnter(index, e)}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
              />
            </div>
          ))}
        </Slider>
        {tooltip.visible && (
          <div
            className={`tooltip ${isLightMode ? 'dark-tooltip' : 'light-tooltip'}`}
            style={{ top: `${tooltip.y + 10}px`, left: `${tooltip.x + 10}px` }}
          >
            {tooltip.text}
          </div>
        )}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Screenshot Modal"
        className="modal"
        overlayClassName="overlay"
      >
        <img src={selectedImage} alt="Selected Screenshot" className="modal-image" />
        <button onClick={closeModal} className="close-button">Close</button>
      </Modal>
    </section>
  );
};

export default Screenshots;
