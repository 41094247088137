import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './AppHeader.css';

const AppHeader = () => {
  const [activeSection, setActiveSection] = useState('about');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      const handleScroll = () => {
        const sections = document.querySelectorAll('section');
        const scrollPos = window.scrollY + window.innerHeight / 3;

        let currentSection = 'about';

        sections.forEach((section) => {
          const sectionTop = section.offsetTop;
          const sectionHeight = section.offsetHeight;
          const sectionId = section.getAttribute('id');

          if (scrollPos >= sectionTop && scrollPos < sectionTop + sectionHeight) {
            currentSection = sectionId;
          }
        });

        setActiveSection(currentSection);
      };

      window.addEventListener('scroll', handleScroll);
      handleScroll();

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [location.pathname]);

  const scrollToSection = (id) => {
    navigate('/');
    setTimeout(() => {
      const section = document.getElementById(id);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
        setActiveSection(id);
      } else {
        console.error(`Element with ID '${id}' not found.`);
      }
    }, 100);
  };

  const refreshApp = () => {
    navigate('/'); // Navigate to the home page
    setTimeout(() => {
      window.scrollTo(0, 0); // Scroll to the top of the page
      window.location.reload(); // Reload the page
    }, 100); // Delay to ensure navigation occurs before reload
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="app-header">
      <h1 className="app-title" onClick={refreshApp}>
        Muscle <span>Meet</span>
      </h1>

      <div className="header-content">
        <button className={`menu-icon ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
      <nav className={`nav-links ${isMenuOpen ? 'show' : ''}`}>
        <a
          href="#about"
          className={activeSection === 'about' && location.pathname === '/' ? 'active' : ''}
          onClick={(e) => {
            e.preventDefault();
            scrollToSection('about');
            toggleMenu();
          }}
        >
          About App
        </a>
        <a
          href="#screenshots"
          className={activeSection === 'screenshots' && location.pathname === '/' ? 'active' : ''}
          onClick={(e) => {
            e.preventDefault();
            scrollToSection('screenshots');
            toggleMenu();
          }}
        >
          Screenshots
        </a>
        <a
          href="#features"
          className={activeSection === 'features' && location.pathname === '/' ? 'active' : ''}
          onClick={(e) => {
            e.preventDefault();
            scrollToSection('features');
            toggleMenu();
          }}
        >
          Features
        </a>
        <a
          href="#reviews"
          className={activeSection === 'reviews' && location.pathname === '/' ? 'active' : ''}
          onClick={(e) => {
            e.preventDefault();
            scrollToSection('reviews');
            toggleMenu();
          }}
        >
          User Reviews
        </a>
        <a
          href="/contact"
          className={location.pathname === '/contact' ? 'active' : ''}
          onClick={(e) => {
            e.preventDefault();
            navigate('/contact');
            toggleMenu();
          }}
        >
          Contact Us
        </a>
      </nav>
    </header>
  );
};

export default AppHeader;
