// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* App Description styles */
.app-description h2,
.app-description h1,
.app-description p {
  margin: 0; 
  
}

.app-description h2 {
  font-size: 24px;
  margin-bottom: 8px; 
}

.app-description h1 {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 8px;
}

.app-description p {
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 0; 
}

/* Responsive breakpoints */
@media (max-width: 768px) {
  .app-description {
    padding: 0 40px;
  }
  .app-description h2 {
    font-size: 22px;
  }
  .app-description h1 {
    font-size: 40px;
  }
  .app-description p {
    font-size: 18px;
  }
}

@media (max-width: 425px) {
  .app-description {
    padding: 0 40px;
    margin-top: 30px;
  }
  .app-description h2 {
    font-size: 20px;
  }
  .app-description h1 {
    font-size: 36px;
  }
  .app-description p {
    font-size: 16px;
  }
}

@media (max-width: 320px) {
  .app-description {
    padding: 0 40px;
  }
  .app-description h2 {
    font-size: 18px;
    margin-bottom: 6px;
  }
  .app-description h1 {
    font-size: 32px;
    margin-bottom: 6px;
  }
  .app-description p {
    font-size: 14px;
    margin-bottom: 0;
  }
}








`, "",{"version":3,"sources":["webpack://./src/components/AppDescription.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;;;EAGE,SAAS;;AAEX;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA,2BAA2B;AAC3B;EACE;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,eAAe;IACf,gBAAgB;EAClB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;EACA;IACE,eAAe;IACf,kBAAkB;EACpB;EACA;IACE,eAAe;IACf,kBAAkB;EACpB;EACA;IACE,eAAe;IACf,gBAAgB;EAClB;AACF","sourcesContent":["/* App Description styles */\n.app-description h2,\n.app-description h1,\n.app-description p {\n  margin: 0; \n  \n}\n\n.app-description h2 {\n  font-size: 24px;\n  margin-bottom: 8px; \n}\n\n.app-description h1 {\n  font-size: 48px;\n  font-weight: bold;\n  margin-bottom: 8px;\n}\n\n.app-description p {\n  font-size: 18px;\n  font-weight: normal;\n  margin-bottom: 0; \n}\n\n/* Responsive breakpoints */\n@media (max-width: 768px) {\n  .app-description {\n    padding: 0 40px;\n  }\n  .app-description h2 {\n    font-size: 22px;\n  }\n  .app-description h1 {\n    font-size: 40px;\n  }\n  .app-description p {\n    font-size: 18px;\n  }\n}\n\n@media (max-width: 425px) {\n  .app-description {\n    padding: 0 40px;\n    margin-top: 30px;\n  }\n  .app-description h2 {\n    font-size: 20px;\n  }\n  .app-description h1 {\n    font-size: 36px;\n  }\n  .app-description p {\n    font-size: 16px;\n  }\n}\n\n@media (max-width: 320px) {\n  .app-description {\n    padding: 0 40px;\n  }\n  .app-description h2 {\n    font-size: 18px;\n    margin-bottom: 6px;\n  }\n  .app-description h1 {\n    font-size: 32px;\n    margin-bottom: 6px;\n  }\n  .app-description p {\n    font-size: 14px;\n    margin-bottom: 0;\n  }\n}\n\n\n\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
