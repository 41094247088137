import React, { useState } from 'react';
import './FeatureLeft.css';
import { triggerZoom } from './DownloadButtons';

const FeatureLeft = ({ title, description, snippets }) => {
  const [selectedSnippet, setSelectedSnippet] = useState(null);

  const openModal = (snippet) => {
    setSelectedSnippet(snippet);
  };

  const closeModal = () => {
    setSelectedSnippet(null);
  };

  const scrollToDownload = () => {
    const downloadSection = document.getElementById('about');
    if (downloadSection) {
      downloadSection.scrollIntoView({ behavior: 'smooth' });
      setTimeout(triggerZoom, 500); // Delay to ensure the scrolling is complete
    }
  };

  return (
    <div className="feature-left">
      <div className="feature-left-content">
        <h1>{title}</h1>
        <p>{description}</p>
        <button className="download-button" onClick={scrollToDownload}>Download Now</button>
      </div>
      <div className="feature-left-snippets">
        {snippets.map((snippet, index) => (
          <div key={index} className="snippet" onClick={() => openModal(snippet)}>
            <img src={snippet} alt={`Snippet ${index + 1}`} />
          </div>
        ))}
      </div>

      {selectedSnippet && (
        <div className="overlay" onClick={closeModal}>
          <div className="modal" onClick={(e) => e.stopPropagation()}>
            <img src={selectedSnippet} alt="Enlarged Snippet" className="modal-image" />
            <button className="close-button" onClick={closeModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FeatureLeft;
