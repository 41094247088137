import React from 'react';
import FeatureLeft from './FeatureLeft';
import FeatureRight from './FeatureRight';
import './Features.css';

const featuresData = [
  {
    type: 'left',
    title: 'Workout Routine',
    description: 'Effortlessly plan and execute your workouts with our streamlined routine feature.',
    snippets: ['snippet1.jpg', 'snippet2.jpg'],
  },
  {
    type: 'right',
    title: 'Workout Library',
    description: 'Access detailed guides to perfect your technique and maximize your results.',
    snippets: ['snippet3.jpg', 'snippet4.jpg'],
  },
  {
    type: 'left',
    title: 'Workout Logs',
    description: 'Save and revisit your workouts to track progress and stay on course.',
    snippets: ['snippet5.jpg', 'snippet6.jpg'],
  },
  {
    type: 'right',
    title: 'Customizable Exercises',
    description: 'Easily adjust exercise order, reps, and weights to fit your goals.',
    snippets: ['snippet7.jpg', 'snippet8.jpg'],
  }  
];

const Features = () => {
  return (
    <section id="features" className="features-section">
      <h2>Reach your <span>goals</span> !</h2>
      <div className="features">
        {featuresData.map((feature, index) => (
          feature.type === 'left' ? (
            <FeatureLeft
              key={index}
              title={feature.title}
              description={feature.description}
              snippets={feature.snippets}
            />
          ) : (
            <FeatureRight
              key={index}
              title={feature.title}
              description={feature.description}
              snippets={feature.snippets}
            />
          )
        ))}
      </div>
    </section>
  );
};

export default Features;
