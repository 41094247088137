import React from 'react';
import AppDescription from './AppDescription';
import AppMockup from './AppMockup';
import DownloadButtons from './DownloadButtons';
import './MainContent.css';

const MainContent = () => {
  return (
    <section id="about" className="about-section">
      <main className="main-content">
        <div className="description-and-buttons">
          <AppDescription />
          <DownloadButtons />
        </div>
        <AppMockup />
      </main>
    </section>
  );
};

export default MainContent;
