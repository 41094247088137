import React from 'react';
import './AppMockup.css';

const AppMockup = () => {
  return (
    <div className="app-mockup">
      <div className="mockup-container">
        <img src="mobile-mockup2.png" alt="Mobile App Screenshot 1" />
        <img src="mobile-mockup1.png" alt="Mobile App Screenshot 2" />
      </div>
    </div>
  );
};

export default AppMockup;
