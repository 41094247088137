import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import './TermsAndConditions.css';

const termsOfService = [
  { type: 'title', text: 'Terms of Service' },
  { type: 'date', text: 'Last Updated: July 31, 2024' },
  {
    type: 'paragraph',
    text: 'Welcome to Muscle Meet, operated by MOTIONROUTES LTD ("Company", "we", "us", or "our"). These Terms of Service ("Terms") govern your use of the Muscle Meet mobile application ("App") and any related services provided by us.',
  },
  {
    type: 'paragraph',
    text: 'By using the App, you agree to these Terms. If you do not agree with any part of these Terms, please do not use the App.',
  },
  { type: 'section', title: '1. Company Information' },
  {
    type: 'paragraph',
    text: 'MOTIONROUTES LTD\n85 Great Portland Street\nLondon, England, W1W 7LT',
  },
  { type: 'section', title: '2. App Description' },
  {
    type: 'paragraph',
    text: 'Muscle Meet is designed to help users log and plan their workouts. The App provides tools for tracking workout progress and creating workout plans.',
  },
  { type: 'section', title: '3. User Accounts' },
  {
    type: 'paragraph',
    text: 'Account Creation: Users must create an account to use the App. Account creation is facilitated through Google OAuth.',
  },
  {
    type: 'paragraph',
    text: 'Age Restrictions: You must be at least 16 years old to create an account and use the App.',
  },
  { type: 'section', title: '4. User Behavior' },
  { type: 'paragraph', text: 'Users must adhere to the following rules:' },
  {
    type: 'list',
    items: [
      'Use the App for personal fitness tracking and planning only.',
      'Do not engage in any illegal activities using the App.',
      'Do not attempt to gain unauthorized access to the App or its systems.',
      'Respect other users and do not engage in harassment, abuse, or any other behavior that could harm others.',
    ],
  },
  { type: 'section', title: '5. Content Submission' },
  {
    type: 'paragraph',
    text: 'Currently, users cannot submit content within the App. This may change with future updates.',
  },
  { type: 'section', title: '6. Privacy Policy' },
  {
    type: 'paragraph',
    text: 'A separate Privacy Policy will be provided to explain how we collect, use, and protect your personal information.',
  },
  { type: 'section', title: '7. Payments' },
  {
    type: 'paragraph',
    text: 'The App is currently free to use. There are no in-app purchases or subscription services at this time.',
  },
  { type: 'section', title: '8. Store Integration' },
  {
    type: 'paragraph',
    text: 'Store features are not yet implemented and will be included in future updates.',
  },
  { type: 'section', title: '9. Liability' },
  {
    type: 'paragraph',
    text: 'We strive to provide accurate and reliable information through the App. However, we do not guarantee the accuracy, completeness, or usefulness of any information provided. The App is provided "as is" and "as available."',
  },
  { type: 'section', title: '10. Limitation of Liability' },
  {
    type: 'paragraph',
    text: 'To the maximum extent permitted by applicable law, MOTIONROUTES LTD shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the App; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; and (iii) any bugs, viruses, trojan horses, or the like that may be transmitted to or through the App by any third party.',
  },
  { type: 'section', title: '11. Termination' },
  {
    type: 'paragraph',
    text: 'We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms. Upon termination, your right to use the App will immediately cease. If you wish to terminate your account, you may simply discontinue using the App.',
  },
  { type: 'section', title: '12. Dispute Resolution' },
  {
    type: 'paragraph',
    text: 'In the event of any dispute arising out of or in connection with these Terms, the parties shall first attempt to resolve the dispute informally. If the dispute is not resolved within 30 days, it shall be referred to and finally resolved by arbitration under the London Court of International Arbitration (LCIA) Rules, which are deemed to be incorporated by reference into this clause. The number of arbitrators shall be one. The seat, or legal place, of arbitration shall be London, England. The language to be used in the arbitral proceedings shall be English.',
  },
  { type: 'section', title: '13. Changes to the Terms' },
  {
    type: 'paragraph',
    text: 'We may update these Terms from time to time. We will notify you of any changes by email or through the App. Your continued use of the App after any such changes constitutes your acceptance of the new Terms.',
  },
  { type: 'section', title: '14. Contact Information' },
  {
    type: 'paragraph',
    text: 'If you have any questions about these Terms, please contact us. ',
  },
  {
    type: 'link',
    text: 'musclemeet.app/contact',
    url: 'https://musclemeet.app/contact',
  },
];

const TermsAndConditions = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const renderContent = () => {
    return termsOfService.map((item, index) => {
      switch (item.type) {
        case 'title':
          return <h1 key={index}>{item.text}</h1>;
        case 'date':
          return <p key={index} className="updated">{item.text}</p>;
        case 'paragraph':
          return <p key={index}>{item.text}</p>;
        case 'section':
          return <h2 key={index}>{item.title}</h2>;
        case 'list':
          return (
            <ul key={index}>
              {item.items.map((listItem, liIndex) => (
                <li key={liIndex}>{listItem}</li>
              ))}
            </ul>
          );
        case 'link':
          return (
            <button
              key={index}
              onClick={() => navigate('/contact')}
              className="contact-button"
            >
              Contact Us
            </button>
          );
        default:
          return null;
      }
    });
  };

  return (
    <div className="terms-container">
      {renderContent()}
    </div>
  );
};

export default TermsAndConditions;
