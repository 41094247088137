import React, { useState, useEffect } from 'react';
import './DownloadButtons.css';

const DownloadButtons = () => {
  const [zoom, setZoom] = useState(false);

  useEffect(() => {
    if (zoom) {
      const timer = setTimeout(() => {
        setZoom(false);
      }, 1000); // Duration of the animation

      return () => clearTimeout(timer);
    }
  }, [zoom]);

  useEffect(() => {
    const handleZoomEvent = () => {
      setZoom(true);
    };

    window.addEventListener('zoomDownloadButtons', handleZoomEvent);

    return () => {
      window.removeEventListener('zoomDownloadButtons', handleZoomEvent);
    };
  }, []);

  return (
    <div className="download-buttons">
      <a href={process.env.REACT_APP_GOOGLE_PLAY_LINK} target="_blank" rel="noopener noreferrer" className={`download-link ${zoom ? 'zoom' : ''}`}>
        <img src="google-play-badge.png" alt="Get it on Google Play" />
      </a>
      <a href={process.env.REACT_APP_APP_STORE_LINK} target="_blank" rel="noopener noreferrer" className={`download-link ${zoom ? 'zoom' : ''}`}>
        <img src="app-store-badge.png" alt="Download on the App Store" />
      </a>
    </div>
  );
};

export const triggerZoom = () => {
  const event = new CustomEvent('zoomDownloadButtons');
  window.dispatchEvent(event);
};

export default DownloadButtons;
