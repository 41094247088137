import React from 'react';
import { FaStar } from 'react-icons/fa';
import './UserReviews.css';

const ReviewCard = ({ review }) => {
  return (
    <div className="review-card">
      <img src={review.image} alt="Dumbbell" />
      <p>{review.text}</p>
      <p><strong>{review.author}</strong></p>
      <div className="rating">
        {Array.from({ length: review.rating }).map((_, index) => (
          <FaStar key={index} />
        ))}
      </div>
    </div>
  );
};

const UserReviews = () => {
  const reviews = [
    {
      id: 1,
      text: "Love this app! Customizable workouts fit my busy schedule perfectly. Motivation guaranteed!",
      author: "FitMom23",
      rating: 5,
      image: "dumbbell.png"
    },
    {
      id: 2,
      text: "Solid app with clear exercise guides. Minor glitches but great for beginners.",
      author: "GymRatSam",
      rating: 4,
      image: "dumbbell.png"
    },
    {
      id: 3,
      text: "Top-notch workout app! Community feature is a plus. Seamless fitness tracker integration.",
      author: "WellnessWanderer",
      rating: 5,
      image: "dumbbell.png"
    },
    {
      id: 4,
      text: "Excellent for cardio tracking. Needs more advanced routines. Heart rate sync is perfect.",
      author: "CardioKing88",
      rating: 4,
      image: "dumbbell.png"
    }
  ];

  return (
    <section id="reviews" className="user-reviews-section">
      <h2>Users <span>reviews</span></h2>
      <div className="reviews-container">
        {reviews.map(review => (
          <ReviewCard key={review.id} review={review} />
        ))}
      </div>
    </section>
  );
};

export default UserReviews;
