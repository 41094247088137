// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* DownloadButtons.css */
.download-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0px;
  transition: transform 0.3s;
}

.download-link img {
  width: 150px; /* Adjust as needed */
  transition: transform 0.3s;
}

.download-link.zoom {
  animation: zoomInOut 1s ease-in-out;
}


.download-buttons a img {
  width: 150px;
  transition: transform 0.3s ease;
}

.download-buttons a img:hover {
  transform: scale(1.1);
}

@keyframes zoomInOut {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

/* Responsive breakpoints */
@media (max-width: 1024px) {
  .download-buttons {
    gap: 0px;
  }
  .download-buttons a img {
    width: 130px;
  }
}

@media (max-width: 768px) {
  .download-buttons {
    gap: 0px;
  }
  .download-buttons a img {
    width: 140px;
  }
}

@media (max-width: 425px) {
  .download-buttons {
    gap: 0px;
  }
  .download-buttons a img {
    width: 140px;
  }
}

@media (max-width: 375px) {
  .download-buttons {
    gap: 0px;
  }
  .download-buttons a img {
    width: 130px;
  }
}

@media (max-width: 320px) {
  .download-buttons {
    gap: 0px;
  }
  .download-buttons a img {
    width: 120px;
  }
}









`, "",{"version":3,"sources":["webpack://./src/components/DownloadButtons.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;EACR,0BAA0B;AAC5B;;AAEA;EACE,YAAY,EAAE,qBAAqB;EACnC,0BAA0B;AAC5B;;AAEA;EACE,mCAAmC;AACrC;;;AAGA;EACE,YAAY;EACZ,+BAA+B;AACjC;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE;IACE,mBAAmB;EACrB;EACA;IACE,qBAAqB;EACvB;AACF;;AAEA,2BAA2B;AAC3B;EACE;IACE,QAAQ;EACV;EACA;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,QAAQ;EACV;EACA;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,QAAQ;EACV;EACA;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,QAAQ;EACV;EACA;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,QAAQ;EACV;EACA;IACE,YAAY;EACd;AACF","sourcesContent":["/* DownloadButtons.css */\n.download-buttons {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 0px;\n  transition: transform 0.3s;\n}\n\n.download-link img {\n  width: 150px; /* Adjust as needed */\n  transition: transform 0.3s;\n}\n\n.download-link.zoom {\n  animation: zoomInOut 1s ease-in-out;\n}\n\n\n.download-buttons a img {\n  width: 150px;\n  transition: transform 0.3s ease;\n}\n\n.download-buttons a img:hover {\n  transform: scale(1.1);\n}\n\n@keyframes zoomInOut {\n  0%, 100% {\n    transform: scale(1);\n  }\n  50% {\n    transform: scale(1.1);\n  }\n}\n\n/* Responsive breakpoints */\n@media (max-width: 1024px) {\n  .download-buttons {\n    gap: 0px;\n  }\n  .download-buttons a img {\n    width: 130px;\n  }\n}\n\n@media (max-width: 768px) {\n  .download-buttons {\n    gap: 0px;\n  }\n  .download-buttons a img {\n    width: 140px;\n  }\n}\n\n@media (max-width: 425px) {\n  .download-buttons {\n    gap: 0px;\n  }\n  .download-buttons a img {\n    width: 140px;\n  }\n}\n\n@media (max-width: 375px) {\n  .download-buttons {\n    gap: 0px;\n  }\n  .download-buttons a img {\n    width: 130px;\n  }\n}\n\n@media (max-width: 320px) {\n  .download-buttons {\n    gap: 0px;\n  }\n  .download-buttons a img {\n    width: 120px;\n  }\n}\n\n\n\n\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
