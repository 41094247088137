// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Main Content styles */
.main-content {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 80px 100px;
}


.description-and-buttons {
  display: flex;
  flex-direction: column;
}

/* Responsive Breakpoints */

/* 1024px */
@media (max-width: 1024px) {
  .main-content {
    padding: 50px;
  }
}

/* 768px */
@media (max-width: 768px) {
  .main-content {
    padding: 20px;
  }
}

/* 425px */
@media (max-width: 425px) {
  .main-content {
    flex-direction: column;
    padding: 30px 20px;
  }
}

/* 375px */
@media (max-width: 375px) {
  .main-content {
    flex-direction: column;
    padding: 25px 15px;
  }
}

/* 320px */
@media (max-width: 320px) {
  .main-content {
    flex-direction: column;  /* Stack elements vertically on smallest screens */
    padding: 20px 10px;     /* Adjust padding for smaller screens */
  }

  .description-and-buttons {
    flex-direction: column; /* Reverse order on smallest screen for better flow */
  }
}







`, "",{"version":3,"sources":["webpack://./src/components/MainContent.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,mBAAmB;AACrB;;;AAGA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA,2BAA2B;;AAE3B,WAAW;AACX;EACE;IACE,aAAa;EACf;AACF;;AAEA,UAAU;AACV;EACE;IACE,aAAa;EACf;AACF;;AAEA,UAAU;AACV;EACE;IACE,sBAAsB;IACtB,kBAAkB;EACpB;AACF;;AAEA,UAAU;AACV;EACE;IACE,sBAAsB;IACtB,kBAAkB;EACpB;AACF;;AAEA,UAAU;AACV;EACE;IACE,sBAAsB,GAAG,kDAAkD;IAC3E,kBAAkB,MAAM,uCAAuC;EACjE;;EAEA;IACE,sBAAsB,EAAE,qDAAqD;EAC/E;AACF","sourcesContent":["/* Main Content styles */\n.main-content {\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  padding: 80px 100px;\n}\n\n\n.description-and-buttons {\n  display: flex;\n  flex-direction: column;\n}\n\n/* Responsive Breakpoints */\n\n/* 1024px */\n@media (max-width: 1024px) {\n  .main-content {\n    padding: 50px;\n  }\n}\n\n/* 768px */\n@media (max-width: 768px) {\n  .main-content {\n    padding: 20px;\n  }\n}\n\n/* 425px */\n@media (max-width: 425px) {\n  .main-content {\n    flex-direction: column;\n    padding: 30px 20px;\n  }\n}\n\n/* 375px */\n@media (max-width: 375px) {\n  .main-content {\n    flex-direction: column;\n    padding: 25px 15px;\n  }\n}\n\n/* 320px */\n@media (max-width: 320px) {\n  .main-content {\n    flex-direction: column;  /* Stack elements vertically on smallest screens */\n    padding: 20px 10px;     /* Adjust padding for smaller screens */\n  }\n\n  .description-and-buttons {\n    flex-direction: column; /* Reverse order on smallest screen for better flow */\n  }\n}\n\n\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
