import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import './PrivacyPolicy.css';

const privacyPolicy = [
  { type: 'title', text: 'Privacy Policy' },
  { type: 'date', text: 'Last Updated: July 31, 2024' },
  {
    type: 'paragraph',
    text: 'MOTIONROUTES LTD ("Company", "we", "us", or "our") operates the Muscle Meet mobile application ("App"). This Privacy Policy explains how we collect, use, disclose, and protect your information when you use our App.',
  },
  {
    type: 'paragraph',
    text: 'By using the App, you agree to the collection and use of information in accordance with this Privacy Policy. If you do not agree with any part of this Privacy Policy, please do not use the App.',
  },
  { type: 'section', title: '1. Company Information' },
  {
    type: 'paragraph',
    text: 'MOTIONROUTES LTD\n85 Great Portland Street\nLondon, England, W1W 7LT',
  },
  { type: 'section', title: '2. Types of Data Collected' },
  {
    type: 'paragraph',
    text: 'We collect the following types of personal information from users:',
  },
  {
    type: 'list',
    items: [
      'Google ID: profile.id',
      'First Name: profile.name.givenName',
      'Last Name: profile.name.familyName',
      'Email: profile.emails[0].value',
      'Email Verified Status: profile.emails[0].verified',
    ],
  },
  { type: 'section', title: '3. Method of Data Collection' },
  {
    type: 'paragraph',
    text: 'The above data is collected via Google OAuth when you create an account and log in to the App.',
  },
  { type: 'section', title: '4. Purpose of Data Collection' },
  {
    type: 'paragraph',
    text: 'We collect and use your data to personalize your experience within the App and improve our services.',
  },
  { type: 'section', title: '5. Data Sharing' },
  {
    type: 'paragraph',
    text: 'We do not currently share your data with third parties. However, we will be using advertising services later on, which may involve sharing certain information with third-party ad networks.',
  },
  { type: 'section', title: '6. Data Storage' },
  {
    type: 'paragraph',
    text: 'Your data is stored on our servers located in Europe.',
  },
  { type: 'section', title: '7. Data Retention' },
  {
    type: 'paragraph',
    text: 'We retain your data for as long as your account is active. If you delete your account, your data will be permanently removed from our servers.',
  },
  { type: 'section', title: '8. Data Security' },
  {
    type: 'paragraph',
    text: 'We take the following measures to protect your data:',
  },
  {
    type: 'list',
    items: [
      'SSL: Secure data transmission.',
      'Encryption: Data is encrypted both in transit and at rest.',
      'Access Controls: Strict access controls to protect your data from unauthorized access.',
    ],
  },
  { type: 'section', title: '9. User Access and Control' },
  {
    type: 'paragraph',
    text: 'You have the right to access, update, or delete your data. You can do this via the settings in the App. Additionally, you have the right to opt-out of data collection or request data portability.',
  },
  { type: 'section', title: "10. Children's Privacy" },
  {
    type: 'paragraph',
    text: 'We only collect information from users who are 16 years of age or older. If you are under 16, please do not use the App.',
  },
  { type: 'section', title: '11. Changes to the Privacy Policy' },
  {
    type: 'paragraph',
    text: 'We may update this Privacy Policy from time to time. We will notify you of any changes by email and through the App. We anticipate updating the Privacy Policy once every major update.',
  },
  { type: 'section', title: '12. Contact Information' },
  {
    type: 'paragraph',
    text: 'If you have any questions about this Privacy Policy, please contact us.',
  },
  {
    type: 'button',
    text: 'Contact Us',
    url: '/contact',
  },
];

const PrivacyPolicy = () => {
  const navigate = useNavigate(); // Initialize useNavigate for client-side navigation

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  return (
    <div className="privacy-container">
      {privacyPolicy.map((section, index) => {
        switch (section.type) {
          case 'title':
            return <h1 key={index}>{section.text}</h1>;
          case 'date':
            return <p key={index} className="updated">{section.text}</p>;
          case 'paragraph':
            return <p key={index}>{section.text}</p>;
          case 'section':
            return <h2 key={index}>{section.title}</h2>;
          case 'list':
            return (
              <ul key={index}>
                {section.items.map((item, itemIndex) => (
                  <li key={itemIndex}>{item}</li>
                ))}
              </ul>
            );
          case 'button':
            return (
              <button 
                key={index} 
                onClick={() => navigate(section.url)} 
                className="contact-button" // Add a custom class for styling
              >
                {section.text}
              </button>
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

export default PrivacyPolicy;
